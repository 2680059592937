
.sk-chase-container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
  }

.sk-chase {
    width: 40px;
    height: 40px;
    position: fixed;
    animation: sk-chase 2.5s infinite linear both;
    z-index: 10;
  }
  
  .sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; 
    animation: sk-chase-dot 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #fff;
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
  .sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }
  
  @keyframes sk-chase {
    100% { transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot {
    80%, 100% { transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.4); 
    } 100%, 0% {
      transform: scale(1.0); 
    } 
  }

  ////// FADE IN TOP //////

  @keyframes fadeInTop {
    0% {
      opacity: 0;
      transform: translateY(150px);
    } 100% {
      opacity: 1;
      transform: translateY(0px);
      
    } 
  }

  @keyframes fadeInLongTop {
    0% {
      opacity: 0;
      transform: translateY(300px);
    } 100% {
      opacity: 1;
      transform: translateY(0px);
      
    } 
  }
  
  .utils__fade-in-fast-top,
  .utils__fade-in-top {
    opacity: 0;
    transition: fadeInTop 1s ease-in both;
  }

  .utils__fade-in-top.appear {
    animation: fadeInTop 1s ease-in-out both 1.25s; 
  }

  .utils__fade-in-fast-top.appear {
    animation: fadeInTop 1s ease-in-out both; 
  }

  /////////////////////////////////////////////////

    ////// FADE IN BOTTOM //////

    @keyframes fadeInBottom {
      0% {
        opacity: 0;
        transform: translateY(-150px);
      } 100% {
        opacity: 1;
        transform: translateY(0px);
        
      } 
    }
  
    @keyframes fadeInLongBottom {
      0% {
        opacity: 0;
        transform: translateY(-300px);
      } 100% {
        opacity: 1;
        transform: translateY(0px);
        
      } 
    }
    
    .utils__fade-in-fast-bottom,
    .utils__fade-in-bottom {
      opacity: 0;
      transition: fadeInBottom 1s ease-in both;
    }
  
    .utils__fade-in-bottom.appear {
      animation: fadeInBottom 1s ease-in-out both 1.25s; 
    }
  
    .utils__fade-in-fast-bottom.appear {
      animation: fadeInBottom 1s ease-in-out both; 
    }
  
    /////////////////////////////////////////////////

  ////// FADE IN CENTER //////

  @keyframes fadeInCenter {
    0% {
      opacity: 0;
      transform: scale(0.2);
    } 100% {
      opacity: 1;
      transform: scale(1);
      
    } 
  }

  @keyframes fadeInLongCenter {
    0% {
      opacity: 0;
      transform: scale(0.2);
    } 100% {
      opacity: 1;
      transform: scale(1);
      
    } 
  }
  
  .utils__fade-in-fast-center,
  .utils__fade-in-center {
    opacity: 0;
    transition: fadeInCenter 1s ease-in both;
  }

  .utils__fade-in-center.appear {
    animation: fadeInCenter 1s ease-in-out both 1.25s; 
  }

  .utils__fade-in-fast-center.appear {
    animation: fadeInCenter 1s ease-in-out both; 
  }

  ///////////////////////////////////////////////// 

  ////// FADE IN RIGHT //////

  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translateX(-150px);
    } 100% {
      opacity: 1;
      transform: translateX(0px);
      
    } 
  }

  @keyframes fadeInLongRight {
    0% {
      opacity: 0;
      transform: translateX(-300px);
    } 100% {
      opacity: 1;
      transform: translateX(0px);
      
    } 
  }
  
  .utils__fade-in-fast-right,
  .utils__fade-in-right {
    opacity: 0;
    transition: fadeInRight 1s ease-in both;
  }

  .utils__fade-in-right.appear {
    animation: fadeInRight 1s ease-in-out both 1.25s; 
  }

  .utils__fade-in-fast-right.appear {
    animation: fadeInRight 1s ease-in-out both; 
  }

  /////////////////////////////////////////////////
    
    ////// FADE IN LEFT //////

    @keyframes fadeInLeft {
      0% {
        opacity: 0;
        transform: translateX(150px);
      } 100% {
        opacity: 1;
        transform: translateX(0px);
        
      } 
    }
  
    @keyframes fadeInLongLeft {
      0% {
        opacity: 0;
        transform: translateX(300px);
      } 100% {
        opacity: 1;
        transform: translateX(0px);
        
      } 
    }
    
    .utils__fade-in-fast-left,
    .utils__fade-in-left {
      opacity: 0;
      transition: fadeInLeft 1s ease-in both;
    }
  
    .utils__fade-in-left.appear {
      animation: fadeInLeft 1s ease-in-out both 1.25s; 
    }
  
    .utils__fade-in-fast-left.appear {
      animation: fadeInLeft 1s ease-in-out both; 
    }
  
    /////////////////////////////////////////////////
    
    @keyframes animateGoLeft {
      0% {
          transform: rotate(-225deg) translate(-5px, -5px);
      }
      50% {
          transform: rotate(-225deg) translate(5px, 5px);
      }
      100% {
          transform: rotate(-225deg) translate(-5px, -5px);
      }
  }
  
  @keyframes animateGoRight {
      0% {
          transform: rotate(-45deg) translate(-5px, -5px);
      }
      50% {
          transform: rotate(-45deg) translate(5px, 5px);
      }
      100% {
          transform: rotate(-45deg) translate(-5px, -5px);
      }
  }

    
    ////// FLICKER IN //////

/* ----------------------------------------------
 * Generated by Animista on 2024-5-23 16:33:27
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * @animation flicker-in-1
 * ----------------------------------------
 */
 @-webkit-keyframes flicker-in-1 {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  10.1% {
    opacity: 1;
  }
  10.2% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  20.1% {
    opacity: 1;
  }
  20.6% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  30.1% {
    opacity: 1;
  }
  30.5% {
    opacity: 1;
  }
  30.6% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  45.1% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  55.1% {
    opacity: 0;
  }
  57% {
    opacity: 0;
  }
  57.1% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  60.1% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  65.1% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  75.1% {
    opacity: 0;
  }
  77% {
    opacity: 0;
  }
  77.1% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  85.1% {
    opacity: 0;
  }
  86% {
    opacity: 0;
  }
  86.1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flicker-in-1 {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0;
  }
  10.1% {
    opacity: 1;
  }
  10.2% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  20.1% {
    opacity: 1;
  }
  20.6% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  30.1% {
    opacity: 1;
  }
  30.5% {
    opacity: 1;
  }
  30.6% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  45.1% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  55.1% {
    opacity: 0;
  }
  57% {
    opacity: 0;
  }
  57.1% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  60.1% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  65.1% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  75.1% {
    opacity: 0;
  }
  77% {
    opacity: 0;
  }
  77.1% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  85.1% {
    opacity: 0;
  }
  86% {
    opacity: 0;
  }
  86.1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
    
    .utils__flicker-in-fast,
    .utils__flicker-in {
      -webkit-transition: flicker-in-1 2s linear both;
      transition: flicker-in-1 2s linear both;
    }
  
    .utils__flicker-in.appear {
      -webkit-animation: flicker-in-1 2s linear both 1.25s;
      animation: flicker-in-1 2s linear both 1.25s;
    }
  
    .utils__flicker-in-fast.appear {
      -webkit-animation: flicker-in-1 2s linear both;
      animation: flicker-in-1 2s linear both;
    }
  
    /////////////////////////////////////////////////